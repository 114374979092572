import * as React from 'react';
import Helmet from 'react-helmet';

const Facebook = ({
  pageUrl = null,
  type = null,
  title = null,
  siteName = null,
  description = null,
  image = null,
  date = null,
  modified = null,
  appID = null,
}) => (
  <Helmet>
    {pageUrl && <meta property="og:url" content={pageUrl} />}
    {type && <meta property="og:type" content={type} />}
    {title && <meta property="og:title" content={title} />}
    {siteName && <meta property="og:site_name" content={siteName} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
    {type === 'article' && date && <meta property="article:published_time" content={date} />}
    {type === 'article' && modified && <meta property="article:modified_time" content={modified} />}
    {modified && <meta property="og:updated_time" content={modified} />}
    {appID && <meta property="fb:app_id" content={appID} />}
    <meta property="og:locale" content="ja_JP" />
  </Helmet>
);

export default Facebook;
