/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { unescape } from '../../utils/htmlEntities';
import Facebook from './Facebook';
import Twitter from './Twitter';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  permalink?: string;
  article?: boolean;
  date?: string;
  modified?: string;
}

const SEO = ({
  title,
  description = '',
  image,
  permalink = '',
  article = false,
  date = '',
  modified = ''
}: Props) => {

  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            apiUrl
            defaultImage: image
            twitterUsername
            facebookAppID
          }
        }
      }
    `
  );
  const { siteMetadata: metadata } = site;

  const seo = {
    title: title || metadata.defaultTitle,
    description: description.replace('<p>', '').replace('</p>', '') || metadata.defaultDescription,
    image: `${image || metadata.apiUrl + metadata.defaultImage}`,
    url: `${permalink.replace(metadata.apiUrl, metadata.siteUrl) || metadata.siteUrl}`,
  };

  return (
    <>
      <Helmet title={seo.title !== metadata.defaultTitle ? unescape(seo.title) : ''} defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <Facebook
        pageUrl={seo.url}
        type={article ? 'article' : 'website'}
        title={seo.title}
        siteName={metadata.defaultTitle}
        description={seo.description}
        image={seo.image}
        date={date}
        modified={modified}
        appID={metadata.facebookAppID}
      />
      <Twitter
        username={metadata.twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
    </>
  );
};


export default SEO;
