import * as React from 'react';
import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import SEO from './SEO';
import Header from './templates/Header';
import Footer from './templates/Footer';
import 'highlight.js/styles/github.css';
import '../styles/all.sass';
import '../styles/rinker.css';
import '../styles/highlight.scss';
import '../styles/block.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(fas, faChevronLeft, fab, faTwitter);


type Props = {
  children: ReactNode
};

const TemplateWrapper = ({ children }: Props) => (
  <div>
    <Helmet bodyAttributes={{ class: '' }} />
    <SEO />
    <Header />
    <Contents>{children}</Contents>
    <Footer />
  </div>
)

export default TemplateWrapper;

const Contents = styled.div`
  position: relative;
  z-index: 1;
`;
