import * as React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        &copy;Copyright {(new Date).getFullYear()} Yohei Isokawa All Rights Reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
