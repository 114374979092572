import { formatToTimeZone } from 'date-fns-timezone';
// import * as moment from 'moment-timezone';

export function formatDate(
  date: string,
  _format: string = 'YYYY.MM.DD',
  options = { timeZone: 'Asia/Tokyo' }
): string {
  return formatToTimeZone(date, _format, options);
  // const timeZone = 'Asia/Tokyo';
  // return moment(date).tz(timeZone).format(_format);
}
