import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Navbar from './Navbar';
import Search from '../Search';

const searchIndices = [
  { name: 'Pages', title: 'Pages', hitComp: 'PageHit' },
  { name: 'Posts', title: 'Blog Posts', hitComp: 'PostHit' }
];

const Header = () => {
  const [isToggleOpen, setIsToggleOpen] = useState<boolean>(false);

  const { site } = useStaticQuery(
    graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            defaultTitle: title
          }
        }
      }
    `
  );
  const { siteMetadata: metadata } = site;

  return (
    <HeaderWrapper isOpen={isToggleOpen}>
      <nav className="navbar is-transparent navbar-first">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              {metadata.defaultTitle}
            </Link>
            <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false"
               data-target="navbarBasicExample"
               onClick={() => setIsToggleOpen(!isToggleOpen)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              <Search indices={searchIndices} />
            </div>
          </div>
        </div>
      </nav>
      <Navbar isOpen={isToggleOpen} />
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  display: block;
  //position: fixed;
  //top: 0;
  //left: 0;
  //right: 0;
  z-index: 30;
  background-color: rgba(255, 255, 255, .9);
  ${(props) => !props.isOpen &&
    'box-shadow: 0 0 4px rgba(0, 0, 0, .15);'
  }
  
  .container {
    max-width: 960px;
  }

  .navbar-first {
    position: relative;
    z-index: 5;
  }

  .navbar, .navbar-menu {
    background-color: transparent;
  }
`;
