export const escape = (str: string): string => {
  const buffer = [];

  // str.split('').forEach((_str: string, i: number): void => {
  //   if (i === str.length) { return; }
  //   buffer.unshift(['&#', _str.charCodeAt(), ';'].join(''));
  // });
  for ( var i=str.length-1; i>=0; i-- ) {
    buffer.unshift( ['&#', str[i].charCodeAt(), ';'].join('') );
  }
  return buffer.join('');
};

export const unescape = (str: string): string => {
  return str.replace( /&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};
