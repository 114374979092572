import * as React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import styled from 'styled-components';

const Navbar = (props) => {
  const menus = [
    { title: 'Angular', url: '/tags/angular/' },
    { title: 'JavaScript', url: '/categories/javascript' },
    { title: 'Ruby/Rails', url: '/categories/ruby' },
    // { title: 'WordPress', url: '/categories/wordpress' },
    // { title: 'プログラミング', url: '/categories/programming' },
    { title: 'フリーランス', url: '/categories/freelance' },
    { title: 'プロフィール', url: '/about' },
    { title: 'お問い合わせ', url: '/contact' },
  ];
  const toggleClass = classNames('navbar-menu', {
    'is-active': props.isOpen
  });

  return (
    <NavContainer className="navbar is-transparent">
      <div id="navbarBasicExample" className={toggleClass}>
        <div className="container">
          <div className="navbar-start">
            {menus.map(menu => (
              <Link
                className="navbar-item"
                to={`${menu.url}`}
                key={menu.url}
              >
                {menu.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

export default Navbar;

const NavContainer = styled.nav`
  position: relative;
  
  @media screen and (max-width: 1023px) {
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, .95) !important;
  }
  
  @media screen and (max-width: 1023px) {
    min-height: 0 !important;
  }
`;
