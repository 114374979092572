import styled, { css } from 'styled-components';
import SearchResult from './search-result';

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 1em;
  border-radius: 2px;
  background: ${({ theme }) => theme.background};
`;

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}
  
  .HitHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
    //padding-top: 1em !important;
    border-bottom: 2px solid;
    
    h3 {
      margin: 0 0 0.5em;
      padding: 0.1em 0.4em;
    }
  }
  
  .HitPageTitle {
    position: relative;
    padding-left: 24px;
  }
  
  .PageIcon {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    vertical-align: middle;
  }

  .HitCount {
    display: flex;
    justify-content: flex-end;
  }

  .Hits {
    margin-bottom: 2.5em;
    
    ul {
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      margin-bottom: 1em;
      
      + li.ais-Hits-item {
        margin-top: 0.7em;
        padding-top: 0.7em;
        border-top: 1px solid;
      }

      a {
        // color: ${({ theme }) => theme.foreground};

        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }
  }
`;
